import config from "@/config";
import _ from "lodash";

/**
 * Redirect to Error Page.
 *
 * @param error
 * @param to
 * @param router
 */
export function redirectToProperErrorPage(error, to, router) {
  if (config.debug) {
    console.log("Redirecting...", error, router, to);
  }

  const status = _.get(error, "response.status");

  switch (status) {
    case 401:
      router.push({ name: "Login" });
      break;
    case 500:
      router.push({
        name: "Error500",
        params: { "0": to.path },
        force: true
      });
      break;
    case 503:
      router.push({
        name: "Error423",
        params: { "0": to.path },
        force: true
      });
      break;
    case 418:
      router.push({
        name: "Error418",
        params: { "0": to.path },
        force: true
      });
      break;
    default:
      router.push({ name: "Error404", params: { "0": to.path } });
  }
}
