import { render, staticRenderFns } from "./BaseItem.vue?vue&type=template&id=30e07954&scoped=true"
import script from "./BaseItem.vue?vue&type=script&lang=js"
export * from "./BaseItem.vue?vue&type=script&lang=js"
import style0 from "./BaseItem.vue?vue&type=style&index=0&id=30e07954&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30e07954",
  null
  
)

export default component.exports