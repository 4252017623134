import _find from "lodash/find";
import store from "@/store";

/**
 * Get "Wet stamp" details.
 *
 * @returns {*}
 */
export function wetStamp() {
  const types = store.state.providers.projectAddons.addons;

  return _find(types, item => item.name === "Wet Stamp");
}

/**
 * Get "Wet stamp 1 - 4" details.
 *
 * @returns {*}
 */
export function wetStampOneToFour() {
  const types = store.state.providers.projectAddons.addons;

  return _find(types, item => item.name === "Wet Stamp - 1 to 4");
}

/**
 * Get "Wet Stamp - 5 to 8" details.
 *
 * @returns {*}
 */
export function wetStampFiveToEight() {
  const types = store.state.providers.projectAddons.addons;

  return _find(types, item => item.name === "Wet Stamp - 5 to 8");
}
