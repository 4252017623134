import _ from "lodash";
import config from "@/config";

/**
 * Log HTTP success response.
 *
 * @param response
 */
export function httpSuccessResponseLogger(response) {
  if (config.debug && _.has(response, "request")) {
    console.info(response);
  }
}

/**
 * Log HTTP error response.
 *
 * @param error
 */
export function httpErrorResponseLogger(error) {
  if (config.debug && _.has(error, "response.request")) {
    console.log(error.response);
  }
}

/**
 * Simple Logger
 *
 * @param args
 */
export function Logger(...args) {
  if (config.debug) {
    console.log(...args);
  }
}
