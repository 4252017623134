import _find from "lodash/find";
import store from "@/store";

/**
 * Get "IEBC Letter" details.
 *
 * @returns {*}
 */
export function iebcLetter() {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.key === "IEBC_LETTER");
}

/**
 * Get "IEBC Letter With Stamped Plans" details.
 *
 * @returns {*}
 */
export function iebcLetterWithStampedPlans() {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.key === "IEBC_LETTER_WITH_STAMPED_PLANS");
}

/**
 * Get "Full Structural" details.
 *
 * @returns {*}
 */
export function fullStructural() {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.key === "FULL_STRUCTURAL");
}

/**
 * Get "Electrical Stamp" details.
 *
 * @returns {*}
 */
export function electricalStamp() {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.key === "ELECTRICAL_STAMP");
}

/**
 * Get "Electrical Fault Study" details.
 *
 * @returns {*}
 */
export function electricalFaultStudy() {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.key === "ELECTRICAL_FAULT_STUDY");
}

/**
 * Get "Plan Set" details.
 *
 * @returns {*}
 */
export function planSet() {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.key === "PLAN_SET");
}

/**
 * Get "Equipment Anchorage" details.
 *
 * @returns {*}
 */
export function equipmentAnchorage() {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.key === "EQUIPMENT_ANCHORAGE");
}

/**
 * Get "Type Object by ID" details.
 *
 * @param id
 * @returns {*}
 */
export function getTypeObjectsById(id) {
  const types = store.state.providers.projectTypes.types;

  return _find(types, item => item.value === id);
}
