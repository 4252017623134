import _get from "lodash/get";

export default {
  node_env: process.env.NODE_ENV,
  base_url: process.env.BASE_URL,
  app_name: process.env.VUE_APP_NAME,
  app_env: _get(process.env, "VUE_APP_ENV", "production"),
  app_url: process.env.VUE_APP_URL,
  debug: _get(process.env, "VUE_APP_DEBUG", "true") === "true",
  app_api_url: process.env.VUE_APP_API_URL,
  app_api_request_url: process.env.VUE_APP_API_REQUEST_URL,
  ray_enabled: _get(process.env, "VUE_APP_RAY_ENABLED", "true") === "true",
  ray_host: process.env.VUE_APP_RAY_HOST,
  ray_port: process.env.VUE_APP_RAY_PORT,
  patch_notes_url: _get(process.env, "VUE_APP_PATCH_NOTES_URL", null),
  app_env_mode: _get(process.env, "VUE_APP_ENV_MODE", "local")
};
