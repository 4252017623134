import _ from "lodash";
import axios from "axios";
import config from "@/config";
import store from "@/store";

const api = axios.create({
  baseURL: _.get(config, "app_api_request_url")
});

api.defaults.params = {};

api.interceptors.request.use(
  config => {
    const access_token = _.get(
      store,
      "state.auth.auth_token.access_token",
      null
    );

    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  async error => {
    const access_token = _.get(store, "state.auth.auth_token.access_token");

    if (!_.isNil(access_token) || !_.isEmpty(access_token)) {
      if (_.get(error, "response.status") === 401) {
        store.commit("auth/INVALIDATE_AUTH_TOKEN");
      }
    }

    return Promise.reject(error);
  }
);

export default api;
