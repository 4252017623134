<template>
  <v-card class="mb-7">
    <v-toolbar flat>
      <v-toolbar-title>
        {{ heading }}
      </v-toolbar-title>

      <v-spacer />
    </v-toolbar>

    <v-divider />

    <div class="pa-4">
      <slot />
    </div>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    heading: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped></style>
