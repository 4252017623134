<template>
  <v-dialog v-model="show" width="400">
    <v-card class="rounded-lg" elevation="0">
      <v-card-text class="pa-0">
        <div class="pa-10 text-center font-weight-medium">
          {{ message }}
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="primary" depressed small rounded @click="hide">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from "@/eventBus";

export default {
  name: "BaseDialog",
  data: () => ({
    show: false,
    message: ""
  }),
  methods: {
    hide() {
      this.show = false;
    }
  },
  mounted() {
    EventBus.$on("baseDialog", (show, message) => {
      this.show = show;
      this.message = message;
    });
  }
};
</script>

<style scoped></style>
