import Vue from "vue";

export const ClickOutsideElement = {
  bind(el, binding) {
    const onClickOutside = event => {
      // Check if the clicked element is not the element itself and not a descendant of the element
      if (!el.contains(event.target) && el !== event.target) {
        // If the binding value is a function, call it
        if (typeof binding.value === "function") {
          binding.value();
        } else {
          console.warn("The provided value is not a function.");
        }
      }
    };

    // Add click event listener to the document
    document.addEventListener("click", onClickOutside);

    // Store the event listener function so we can remove it later
    el._clickOutsideEvent = onClickOutside;
  },

  unbind(el) {
    // Remove the click event listener from the document when the directive is unbound
    document.removeEventListener("click", el._clickOutsideEvent);
  }
};

Vue.directive("click-outside-element", ClickOutsideElement);
