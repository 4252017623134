require("./bootstrap");
require("./filters");

// TODO: find a way to recursively require all files in a folder
// sample: Glob - npm
require("./directives/ClickOutsideElement");

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/* Vuebar */
import Vuebar from "vuebar";

Vue.use(Vuebar);
/* Vuebar */

/* VueSkycons */
import VueSkycons from "vue-skycons";

Vue.use(VueSkycons, {
  color: "#1e88e5"
});
/* VueSkycons */

/* Vuetify */
import vuetify from "./plugins/vuetify";
/* Vuetify */

/* Font Awesome */
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import "@/plugins/fontawesome";

library.add(fas);
library.add(far);
library.add(fab);

dom.watch();

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
/* Font Awesome */

/* Vue Head */
import VueHead from "vue-head";

Vue.use(VueHead, {
  separator: "::"
});
/* Vue Head */

/* BraintreeDropinVue */
import BraintreeDropinVue from "braintree-dropin-vue";

Vue.use(BraintreeDropinVue);
/* BraintreeDropinVue */

/* VueGoogleMaps */
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    // This is required to use the Autocomplete plugin
    libraries: "places" // 'places,drawing,visualization',
  }
});
/* VueGoogleMaps */

/* VuetifyGoogleAutocomplete */
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete";

Vue.use(VuetifyGoogleAutocomplete, {
  vueGoogleMapsCompatibility: true
});
/* VuetifyGoogleAutocomplete */

/**/
// import plugin
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
// don't forget to import CSS styles
import "tiptap-vuetify/dist/main.css";
/**/

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi"
});

import VueTour from "vue-tour";
require("vue-tour/dist/vue-tour.css");
Vue.use(VueTour);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
