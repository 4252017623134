<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    @click="refresh"
    :active-class="``"
  >
    <template v-if="item.isIconPng === true">
      <v-list-item-icon>
        <v-img
          src="@/assets/images/icons/plus-box.svg"
          :width="30"
          :height="30"
        />
      </v-list-item-icon>
    </template>

    <template v-else-if="is_icon_fapzse">
      <v-list-item-icon>
        <i :class="[item.icon]"></i>
      </v-list-item-icon>
    </template>

    <template v-else>
      <v-list-item-icon v-if="item.icon">
        <v-icon v-text="item.icon" />
      </v-list-item-icon>
    </template>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import Themeable from "vuetify/lib/mixins/themeable";
import { mapGetters, mapState } from "vuex";

export default {
  name: "BaseItem",
  mixins: [Themeable],
  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
        isIconPng: false
      })
    },
    text: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    refresh() {
      if (
        this.item.key === "create-project" &&
        this.$router.currentRoute.name === "CreateProject" &&
        this.is_project_done
      ) {
        this.$router.go(this.$router.currentRoute);
      }
    }
  },
  computed: {
    ...mapState({
      current_step: state => state.createProject.current_step
    }),
    ...mapGetters({
      is_project_done: "createProject/is_project_done"
    }),
    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
    is_icon_fapzse() {
      return this.item.icon.indexOf("fapzse") > -1;
    }
  }
};
</script>

<style lang="scss" scoped>
[data-prefix="fapzse"] {
  font-size: 20px !important;
}
</style>
